.TopBar-wrapper {
    width: 100%;
    height: var(--topbar_height);
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: var(--primary_light);
    border-radius: 0px 0px 0px 0px;
    z-index: 1;
    display: flex;
    min-width: var(--min_width);
}
.TopBar-wrapper div {
    float:inline-start
}

.TopBar-name-wrapper {
    align-content: center;
}
.TopBar-name-wrapper p{
    margin: 0px 0px 0px 75px;
    font-size: calc(var(--topbar_height) * 8 / 10);
    font-weight: 500;
}

.TopBar-job-wrapper {
    align-content: center;
}
.TopBar-job-wrapper p{
    margin: 0px 0px 0px 75px;
    font-size: calc(var(--topbar_height) * 5 / 10);
}
















/* .TopBar-wrapper div {
    float:inline-start
}
.TopBar-name-bubble {
    width: fit-content;
    height: 100%;
    z-index: inherit;
    background-color: rgb(88, 123, 168);
    border-radius: 0px 0px 32px 0px;
    padding: 0px 64px 0px 89px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
}
.TopBar-name-text {
    font-size: 4vmax;
    font-weight: bold;
    margin: 0px;
    align-self: flex-end;
    text-wrap: nowrap;
    padding-bottom: 4px;
    color: #ffffff;
}
.TopBar-profession-bubble {
    width: fit-content;
    height: 100%;
    z-index: inherit;
    padding: 0px 32px 0px 32px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
}
.TopBar-profession-text {
    font-size: 3.5vmax;
    font-style: italic;
    font-weight: bold;
    text-wrap: nowrap;
    margin: 0px;
    align-self: flex-end;
    padding-bottom: 4px;
    color: #000000;
} */
