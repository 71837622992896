.SideNav-wrapper {
    height: calc(100% - var(--topbar_height));
    z-index: 10;
    top: var(--topbar_height);
    left: 0px;
    position: fixed;
    transition: left .5s;
    min-height: 650px;
}
.SideNav-wrapper:hover {
    left: 200px;
}
.SideNav-tab {
    width: 50px;
    height: 100%;
    padding: 50px 0px 0px 0px;
    border-radius: 0px 0px 40px 0px;
    padding: 50px 0px 0px 0px;
    top: 0px;
    left: 0px;
    z-index: inherit;
    position: absolute;
    background-color: var(--secondary_light);
}
.SideNav-tab-swoop {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: var(--primary_light);
}
.SideNav-tab-swoop div {
    width: 100%;
    height: 100%;
    border-radius: 50px 0px 0px 0px;
    background-color: var(--secondary_light);
}
.SideNav-tab-icon {
    width: 80%;
    padding: 10%;
}
.SideNav-list {
    width: 200px;
    height: 100%;
    top: 0px;
    left: -200px;
    position: absolute;
    padding-top: 32px;
    z-index: inherit;
    overflow-x: hidden;
    background-color: var(--primary_light);
}
.SideNav-list-swoop {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 32px;
    right: 0px;
    background-color: var(--secondary_light);
}
.SideNav-list-swoop div {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 40px 0px;
    background-color: var(--primary_light);
}
.SideNav-list a {
    padding: 32px 32px;
    text-align: right;
    text-decoration: none;
    font-size: 35px;
    color: #ffffff;
    border-radius: 0px 10px 10px 0px;
    display: block;
}
/* Change the color of links on hover */
.SideNav-list a:hover {
    background-color: #ddd;
    color: black;
}
/* Add a color to the active/current link */
.SideNav-list a.active {
    background-color: #98b9c9;
    color: rgb(0, 0, 0);
}
