.Projects-main-content {
    align-content: center;
    align-items: center;
    width: 95%;
    margin: auto;
    margin-top: 1%;
}

.Projects-projects-table {
    width: 100%;
}
.Projects-projects-tbody tr {
}
.Projects-projects-tbody td {
    padding: 0px;
    width: 50%;
}

.Projects-under-construction-wrapper {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    width: 75%;
    margin-left: 12.5%;
    margin-top: 12.5%;
    margin-bottom: 12.5%;
    align-content: center;
    padding: 4vmax;
    background-color: #9ef5f5;
    border-radius: 3vmax;
    border: solid 1vmax #43caca;
}
.Projects-under-construction-text {
    text-align: center;
    font-size: 4vmax;
    font-weight: bold;
    margin: 0px;
}