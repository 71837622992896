.App {
  position: absolute;
  top: 0px;
  left: 0px;
}

.App-main-content-wrapper {
  position: fixed;
  top: var(--topbar_height);
  left: 50px;
  bottom: 0px;
  right: 0px;
  /* padding: 1vmax 1vmax 1vmax 1vmax; */
  overflow-y: scroll;
  min-width: var(--min_width);
  min-height: var(--min_height);
}

::-webkit-scrollbar {
  width: 1vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: #00000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 0.5vw;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}